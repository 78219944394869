import React from "react";
import CustomLinkButton from "../custom-link-button/custom-link-button.component";
import "./service-service.styles.scss";
const ServiceService = ({
  children,
  imgSrc,
  imgAlt,
  title,
  price,
  scratchPrice,
  inclusions,
  optionId
}) => (
  <div className="service-service">
    <img className="service-service-image" src={imgSrc} alt={imgAlt} />
    <div className="service-service-content">
      <h2 className="service-service-header">
        {title} - <span className="scratch-price">{scratchPrice}</span> <span className="service-price">{price}</span>
      </h2>
      <div className="service-service-description">{children}</div>
      <div className="service-service-inclusions-wrapper">
        <ul className="service-service-inclusions">
          {inclusions.map(inclusion => (
            <li key={inclusion}>{inclusion}</li>
          ))}
        </ul>
        <CustomLinkButton isCloud={true} to={`/contact/${optionId}`}>
          Book This
        </CustomLinkButton>
      </div>
    </div>
  </div>
);

export default ServiceService;
