import React from "react";
import { Link } from "react-router-dom";

import FLAVORS_DATA from "./flavors.data.js";
import PageSection from "../page-section/page-section.component";
import "./flavors.styles.scss";

const Flavors = () => (
  <div id="#flavors" className="flavors">
    <PageSection title="Flavors">
      We have a variety of classic and specialty flavors available for you to
      choose from for your event and are adding new flavors all the time. Don't
      see a flavor you are looking for?{" "}
      <Link className="inline-link" to="/contact">
        Let us know
      </Link>{" "}
      and we can work together to create something new!
    </PageSection>
    <div className="flavor-lists-wrapper">
      <PageSection title="Specialty Flavors">
        <ul className="flavor-list">
          {FLAVORS_DATA.SPECIALTY.map(({flavorName,isNew, isSpicy}) => (
            <li key={flavorName}>{flavorName}<span className="flavor-badge">{`${isNew ? "New!" : ""}`}</span><span className="spicy-badge">{`${isSpicy ? "Spicy!" : ""}`}</span></li>
          ))}
        </ul>
      </PageSection>
      <PageSection title="Classic Flavors">
        <ul className="flavor-list">
          {FLAVORS_DATA.CLASSIC.map(({flavorName}) => (
            <li key={flavorName}>{flavorName}</li>
          ))}
        </ul>
      </PageSection>
    </div>
  </div>
);
export default Flavors;
