import React from "react";

import './checkmark.styles.scss';

const Checkmark = () => (
  <svg className="checkmark" xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 200 200">
    <g fill="none" fillRule="evenodd">
      <circle className="checkmark--circle" cx="100" cy="100" r="84.615" fill="#c7eae4"/>
      <polyline className="checkmark--check" stroke="#FFF"  points="76.923 130.769 123.077 130.769 123.077 38.462" transform="rotate(42 100 84.615)"/>
    </g>
  </svg>
  );

export default Checkmark