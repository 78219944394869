import React from "react";
import FormField from '../form-field/form-field.component';

import "./form-textarea.styles.scss";

const FormInput = ({ handleChange, ...otherProps }) => (
  <FormField {...otherProps}>
    <textarea
      className="form-input form-textarea"
      onChange={handleChange}
      {...otherProps}
    />
  </FormField>
);

export default FormInput;