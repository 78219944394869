const FLAVORS_DATA = {
  SPECIALTY: [{
    flavorName: "Sweet Pickle",
    isNew: false,
    isSpicy: false,
    imgSrc: "https://picsum.photos/id/1062/20/20?blur=2",
    imgAlt: "Two Cotton Candy Fluffs",
  },{
    flavorName: "Hot Chili Mango",
    isNew: false,
    isSpicy: true,
    imgSrc: "https://picsum.photos/id/1062/20/20?blur=2",
    imgAlt: "Two Cotton Candy Fluffs",
  },{
    flavorName: "Sweet as a Peach",
    isNew: false,
    imgSrc: "https://picsum.photos/id/1062/20/20?blur=2",
    imgAlt: "Two Cotton Candy Fluffs",
  },{
    flavorName: "Root Beer",
    imgSrc: "https://picsum.photos/id/1062/20/20?blur=2",
    imgAlt: "Two Cotton Candy Fluffs",
  },
  // {
  //   flavorName: "Summer Passion",
  //   imgSrc: "https://picsum.photos/id/1062/20/20?blur=2",
  //   imgAlt: "Two Cotton Candy Fluffs",
  // },
  {
    flavorName: "Cherry Lime",
    imgSrc: "https://picsum.photos/id/1065/20/20?blur=2",
    imgAlt: "Two Cotton Candy Fluffs",
  },{
    flavorName: "Birthday Cake",
    imgSrc: "https://picsum.photos/id/1066/20/20?blur=2",
    imgAlt: "Two Cotton Candy Fluffs",
  },{
    flavorName: "Fall Spice",
    imgSrc: "https://picsum.photos/id/1066/20/20?blur=2",
    imgAlt: "Two Cotton Candy Fluffs",
  },
  // {
  //   flavorName: "Winter Mint",
  //   imgSrc: "https://picsum.photos/id/1066/20/20?blur=2",
  //   imgAlt: "Two Cotton Candy Fluffs",
  // },
  {
    flavorName: "Bacon",
    imgSrc: "https://picsum.photos/id/1066/20/20?blur=2",
    imgAlt: "Two Cotton Candy Fluffs",
  },
  // {
  //   flavorName: "Maple",
  //   imgSrc: "https://picsum.photos/id/1066/20/20?blur=2",
  //   imgAlt: "Two Cotton Candy Fluffs",
  // },
  {
    flavorName: "Piña Colada",
    imgSrc: "https://picsum.photos/id/1066/20/20?blur=2",
    imgAlt: "Two Cotton Candy Fluffs",
  },{
    flavorName: "Passion Fruit",
    imgSrc: "https://picsum.photos/id/1066/20/20?blur=2",
    imgAlt: "Two Cotton Candy Fluffs",
  },
  // {
  //   flavorName: "Bubble Gum",
  //   imgSrc: "https://picsum.photos/id/1066/20/20?blur=2",
  //   imgAlt: "Two Cotton Candy Fluffs",
  // }
],
  CLASSIC: [{
    flavorName: "Pink Vanilla",
    imgSrc: "https://picsum.photos/id/1062/20/20?blur=2",
    imgAlt: "Two Cotton Candy Fluffs",
  },{
    flavorName: "Blue Raspberry",
    imgSrc: "https://picsum.photos/id/1063/20/20?blur=2",
    imgAlt: "Two Cotton Candy Fluffs",
  },{
    flavorName: "Grape",
    imgSrc: "https://picsum.photos/id/1064/20/20?blur=2",
    imgAlt: "Two Cotton Candy Fluffs",
  },{
    flavorName: "Apple",
    imgSrc: "https://picsum.photos/id/1065/20/20?blur=2",
    imgAlt: "Two Cotton Candy Fluffs",
  },
  // {
  //   flavorName: "Cherry",
  //   imgSrc: "https://picsum.photos/id/1065/20/20?blur=2",
  //   imgAlt: "Two Cotton Candy Fluffs",
  // },
  {
    flavorName: "Watermelon",
    imgSrc: "https://picsum.photos/id/1065/20/20?blur=2",
    imgAlt: "Two Cotton Candy Fluffs",
  },{
    flavorName: "Orange",
    imgSrc: "https://picsum.photos/id/1065/20/20?blur=2",
    imgAlt: "Two Cotton Candy Fluffs",
  },{
    flavorName: "Strawberry",
    imgSrc: "https://picsum.photos/id/1065/20/20?blur=2",
    imgAlt: "Two Cotton Candy Fluffs",
  },{
    flavorName: "Banana",
    imgSrc: "https://picsum.photos/id/1065/20/20?blur=2",
    imgAlt: "Two Cotton Candy Fluffs",
  },{
    flavorName: "Lime",
    imgSrc: "https://picsum.photos/id/1065/20/20?blur=2",
    imgAlt: "Two Cotton Candy Fluffs",
  }]
};

export default FLAVORS_DATA;