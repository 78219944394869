import React from 'react';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { selectCollectionsForPreview } from '../../redux/shop/shop.selectors';
import CollectionPreview from '../collection-preview/collection-preview.component';
import PageHeader from '../../components/page-header/page-header.component';
import './collections-overview.styles.scss';

const CollectionsOverview = ({ shopItems }) => {
  useEffect(() => {
    document.title = "Columbus Candy - Shop";
  }, []);
  return (
    <div className='collections-overview'>
      <PageHeader contentClasses="centered" title="Shop Cotton Candy">Packaged cotton candy is best enjoyed within three days of pickup date.</PageHeader>
      {shopItems.map(({ id, ...otherCollectionProps }) => (
        <CollectionPreview key={id} {...otherCollectionProps} />
      ))}
    </div>
  )
};

const mapStateToProps = createStructuredSelector({
  shopItems: selectCollectionsForPreview,
});

export default connect(mapStateToProps)(CollectionsOverview);
