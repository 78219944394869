
import oneHour from "../../assets/single-hour.png";
import twoHour from "../../assets/two-hour.png";
import threeHour from "../../assets/three-hour.png";
const SERVICE_DATA = [
  {
    title: "Power Hour",
    price: "$300",
    inclusions: [
      "1 hour of cart service",
      "3 flavors",
      "cart attendant"
    ],
    imgSrc: oneHour,
    imgAlt: "One Cotton Candy Fluff",
    description: "For shorter events, this option is perfect!",
    optionId: "1-Hour"
  },
  {
    title: "Double Down",
    scratchPrice: "$600",
    price: "$500",
    inclusions: [
      "2 hours of cart service",
      "4 flavors",
      "cart attendant"
    ],
    imgSrc: twoHour,
    imgAlt: "Two Cotton Candy Fluffs",
    description: "Choose this for larger and longer events.",
    optionId: "2-Hour"
  },
  {
    title: "Party! Super Party!",
    scratchPrice: "$900",
    price: "$650",
    inclusions: [
      "3 hours of cart service",
      "5 flavors",
      "cart attendant"
    ],
    imgSrc: threeHour,
    imgAlt: "Three Cotton Candy Fluffs",
    description:
      "Keep your guests entertained all night with this three hour option.",
    optionId: "3-Hour"
  }
];

export default SERVICE_DATA;
