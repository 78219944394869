import React from "react";

import HomepageService from "../homepage-service/homepage-service.component";
import "./homepage-service-list.styles.scss";

const services = [
  {
    id: 1,
    title: "Weddings",
    content:
      "We love adding sweetness to a wedding reception. Invite us to add a unique twist to the day that will delight your guests!",
    imgSrc: "/images/wedding.png",
    imgAlt: "cotton candy at a wedding"
  },
  {
    id: 2,
    title: "Parties",
    content:
      "Add magic to your social event. If you are having a family reunion or birthday party, we can help add extra flavor.",
    imgSrc: "/images/parties.jpg",
    imgAlt: "cotton candy at a party"
  },
  {
    id: 3,
    title: "Corporate Events",
    content:
      "We love adding sweetness to a corporate event. Show your coworkers that you are not all business and no fun. Invite us to sweeten the deal.",
    imgSrc: "/images/corporate.jpg",
    imgAlt: "cotton candy at a corporate event"
  }
];

const HomepageServiceList = () => (
  <div className="homepage-service-list">
    {services.map(({ id, content, ...otherSectionProps }) => (
      <HomepageService key={id} {...otherSectionProps}>
        {content}
      </HomepageService>
    ))}
  </div>
);
export default HomepageServiceList;
