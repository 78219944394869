const SHOP_DATA = {
  giftboxes: {
    id: 1,
    title: 'Gift Boxes',
    routeName: 'gift boxes',
    items: [
      {
        id: 1,
        name: 'Multi-Colored Gift Box',
        imageUrl: '/images/tri-color-box-resize.jpeg',
        price: 20,
        description: "Pink Vanilla, Birthday Cake, and Blue Raspberry. Custom flavors are available depending on availability."
      },
      {
        id: 2,
        name: 'Two-Colored Gift Box',
        imageUrl: '/images/two-color-resize.jpg',
        price: 20,
        description: "Classic Blue Raspberry and Birthday Cake. Custom flavors are available depending on availability."
      }
    ]
  },
  gifts: {
    id: 3,
    title: 'Gifts',
    routeName: 'gifts',
    items: [
      // {
      //   id: 3,
      //   name: 'Multi-Colored Duo',
      //   imageUrl: '/images/doubleMulti.jpg',
      //   price: 15,
      //   description: "Three flavors in two bags. Shown with Grape, Blue Raspberry and Cherry-lime."
      // },
      // {
      //   id: 21120231,
      //   name: "Valentine Party Pouch - Stripes",
      //   imageUrl: '/images/stripe-heart-square.jpg',
      //   price: 3.50,
      //   description:  "This striped cotton candy pouch is the perfect treat for Valentine's Day, birthday parties, and baby showers!",
      //   longDescription: "Indulge in the sweet and fluffy goodness of our cotton candy! Our cotton candy comes in a 5 1/8\" x 3 1/8\" x 8 1/8\" pouch with a heart shaped window, allowing you to see the vibrant colors of your favorite flavor. The pouch features a classic pink and white striped design that is perfect for any occasion. Choose from a variety of flavors to find your perfect treat. Whether you're a fan of traditional pink vanilla or looking for something more unique like blue raspberry, we have the perfect flavor for you. Enjoy the taste of your favorite childhood treat whenever and wherever you want with our convenient pouch."
      // },
      // {
      //   id: 21120232,
      //   name: "Valentine Party Pouch - Hearts",
      //   imageUrl: '/images/heart-heart-square.jpg',
      //   price: 3.50,
      //   description:  "This heart cotton candy pouch is the perfect treat for Valentine's Day, birthday parties, and baby showers!",
      //   longDescription: "Indulge in the sweet and fluffy goodness of our cotton candy! Our cotton candy comes in a 5 1/8\" x 3 1/8\" x 8 1/8\" pouch with a heart shaped window, allowing you to see the vibrant colors of your favorite flavor. The pouch features a classic pink and white striped design that is perfect for any occasion. Choose from a variety of flavors to find your perfect treat. Whether you're a fan of traditional pink vanilla or looking for something more unique like blue raspberry, we have the perfect flavor for you. Enjoy the taste of your favorite childhood treat whenever and wherever you want with our convenient pouch."
      // },
      {
        id: 21120233,
        name: "Valentine Party Pouch - 2 for $6",
        imageUrl: '/images/vday-both-square.png',
        price: 6,
        description:  "These cotton candy pouchs are the perfect treat for Valentine's Day, birthday parties, and baby showers!",
        longDescription: "Indulge in the sweet and fluffy goodness of our cotton candy! Our cotton candy comes in a 5 1/8\" x 3 1/8\" x 8 1/8\" pouch with a heart shaped window, allowing you to see the vibrant colors of your favorite flavor. The pouch features a classic pink and white striped design that is perfect for any occasion. Choose from a variety of flavors to find your perfect treat. Whether you're a fan of traditional pink vanilla or looking for something more unique like blue raspberry, we have the perfect flavor for you. Enjoy the taste of your favorite childhood treat whenever and wherever you want with our convenient pouch."
      },
      {
        id: 32720231,
        name: "Easter Party Pouch - 2 for $6",
        imageUrl: '/images/easter-square-small.png',
        price: 6,
        description:  "These cotton candy pouchs are the perfect treat for an easter basket!",
        longDescription: "Indulge in the sweet and fluffy goodness of our cotton candy! Our cotton candy comes in a 5 1/8\" x 3 1/8\" x 8 1/8\" pouch with an egg shaped window, allowing you to see the vibrant colors of your favorite flavor. The pouch features a classic pink and white striped design that is perfect for any occasion. Choose from a variety of flavors to find your perfect treat. Whether you're a fan of traditional pink vanilla or looking for something more unique like blue raspberry, we have the perfect flavor for you. Enjoy the taste of your favorite childhood treat whenever and wherever you want with our convenient pouch."
      },
      {
        id: 4,
        name: "Glitter Bomb - Pink",
        imageUrl: '/images/pinkGlitterBomb.jpg',
        price: 2.50,
        description:  "Cotton candy filled with edible glitter that melts into your favorite clear bubbly drink transforming it into a shimmering wonder."
      },
      {
        id: 12,
        name: "Glitter Bomb - Green",
        imageUrl: '/images/green_elements_square_small.jpg',
        price: 2.50,
        description:  "Cotton candy filled with edible glitter that melts into your favorite clear bubbly drink transforming it into a shimmering wonder."
      },
      {
        id: 22220231,
        name: "Color Reveal Glitter Bomb - Pink",
        imageUrl: 'images/pink-gender-small-square.jpg',
        price: 2.50,
        description: "These bombs dissolve from white cotton candy into your favorite bubbly drink, revealing a burst of glitter and changing the color of your drink to pink."
      },
      {
        id: 22220232,
        name: "Color Reveal Glitter Bomb - Blue",
        imageUrl: 'images/blue-gender-small-square.jpg',
        price: 2.50,
        description: "These bombs dissolve from white cotton candy into your favorite bubbly drink, revealing a burst of glitter and changing the color of your drink to blue."
      }
    ]
  },
  cakes: {
    id: 4,
    title: 'Cakes',
    routeName: 'cakes',
    items: [
      {
        id: 5,
        name: 'Cotton Candy Cake',
        imageUrl: '/images/wideCake-2-small.jpg',
        price: 20,
        description: "Cotton candy cake great for a unique birthday cake."
      },
      // {
      //   id: 6,
      //   name: "Tall Cotton Candy Cake",
      //   imageUrl: '/images/tallCake-small.jpg',
      //   price: 20,
      //   description:  "Tall cotton candy cake great for a unique birthday cake."
      // }
    ]
  },
  bulk: {
    id: 2,
    title: 'Bulk',
    routeName: 'bulk',
    items: [
      {
        id: 10,
        name: 'Bags 10 count',
        imageUrl: '/images/bulk10placeholder.jpg', 
        price: 35,
        description: "10 bags of one flavor. We will contact you for your desired flavor."
      },
      {
        id: 11,
        name: 'Bags 100 count',
        imageUrl: '/images/bulk100placeholder.jpg',
        price: 300,
        description: "Reduced price for orders of 100 bags. We will contact you for your desired flavor."
      }
      
    ]
  },
 
};

export default SHOP_DATA;