import React from "react";
import FormField from '../form-field/form-field.component';

import "./form-input.styles.scss";

const FormInput = ({ handleChange, ...otherProps }) => (
  <FormField {...otherProps}>
    <input
      type="text"
      className="form-input"
      onChange={handleChange}
      {...otherProps}
    />
  </FormField>
);

export default FormInput;