import React, { useEffect } from 'react';

import CustomLinkButton from "../../components/custom-link-button/custom-link-button.component";
import PageHeader from "../../components/page-header/page-header.component";
import HomepageServiceList from "../../components/homepage-service-list/homepage-service-list.component";
import "./homepage.styles.scss";
import { Link } from "react-router-dom";

const Homepage = () => {
  useEffect(() => {
    document.title = "Columbus Candy"
  }, []);

  return (
    <div className="homepage">
      <PageHeader title="Experience Columbus Cotton Candy" subtitle="-We Spin a Smile!- ">
        <div className="offering-container">
          <Link to="/services">
            <div className="offering-cta">
              <h2>Event Services</h2>
              <div className="offering-container">
                <img className='product-image' src="/images/event-spinning.jpg" alt="cotton candy stand" />
              </div>
              <div className="inline-link">
                Click here to learn more
              </div>
            </div>
          </Link>
          <Link to="/shop">
            <div className="offering-cta">
              <h2>Gift Orders</h2>
              <div className="offering-container">
                <img className='product-image' src="/images/tri-color-box-resize.jpeg" alt="pink, white, and green cotton candy in a bakery box" />
              </div>
              <div className="inline-link">
                Click here to order
              </div>
            </div>
          </Link>
        </div>

        Looking for a fun and unique way to add flavor to your special occasion? Columbus Cotton Candy offers cotton candy services for weddings, parties, corporate events, and more. Choose from a variety of unique flavors and let us spin a smile right before your eyes.
        <br /><br />

      </PageHeader>
      {/* Insert Review  */}
      <HomepageServiceList />
      <CustomLinkButton isCloud={false} to="/services">Learn More</CustomLinkButton>
    </div>
  );
}

export default Homepage;
