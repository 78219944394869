import React, { useState } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Link } from 'react-router-dom';
import { useEffect } from 'react';

import {
  selectCartItems,
  selectCartTotal,
} from '../../redux/cart/cart.selectors';

import CheckoutItem from '../../components/checkout-item/checkout-item.component';
import CustomButton from '../../components/custom-button/custom-button.component';
import PickupDate from '../../components/pickup-date/pickup-date.component';

import CircleLoader from "react-spinners/CircleLoader";
import { sendToStripeCheckout } from "../../firebase/firebase.utils";
import { addDays } from "date-fns";

import './checkout.styles.scss';

const CheckoutPage = ({ cartItems, total }) => {
  useEffect(() => {
    document.title = "Columbus Candy - Checkout";
  }, []);

  // const nDaysAway = addDays(new Date(), 2);
  const nDaysAway = addDays(new Date(), 1);
  var originalStartDate = new Date(nDaysAway.getFullYear(), nDaysAway.getMonth(), nDaysAway.getDate());
  const [startDate, setStartDate] = useState(originalStartDate);
  const [processingState, setProcessingState] = useState("notProcessing");

  const sendLineItemsToStripe = () => {
    setProcessingState("processing");
    const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    const formatedPickupDate = startDate.toLocaleDateString("en-US", options);

    sendToStripeCheckout(cartItems, formatedPickupDate);
  };

  return (
    <div className='checkout-page'>
      <div className='checkout-header'>
        <div className='header-block'>
          <span>Product</span>
        </div>
        <div className='header-block header-block-desc'>
          <span>Description</span>
        </div>
        <div className='header-block'>
          <span>Quantity</span>
        </div>
        <div className='header-block header-block-price'>
          <span>Price</span>
        </div>
        <div className='header-block'>
          <span>Remove</span>
        </div>
      </div>
      {cartItems.length ? (
        cartItems.map((cartItem) => <CheckoutItem key={cartItem.id} cartItem={cartItem} />)
      ) : (
        <div className="empty-message">Your cart is empty.
          <div>
            <Link className="inline-link" to="/shop"> - Go to shop page - </Link>
          </div>
        </div>

      )}
      {cartItems.length ? (
        <div className="checkout-footer">
          <div className="total">{`TOTAL: $${total.toFixed(2)}`}</div>
          <PickupDate startDate={startDate} setStartDate={setStartDate} originalStartDate={originalStartDate} />
          <CustomButton onClick={sendLineItemsToStripe} disabled={processingState === "processing"}
          ><span className="pay-now-text">
              {`${processingState === "processing" ? "Processing" : "Pay Now"}`}
            </span>
            <CircleLoader color={"#c7eae4"} loading={processingState === "processing"} size={30} />
          </CustomButton>
        </div>
      ) : ""}

    </div>
  )
};

const mapStateToProps = createStructuredSelector({
  cartItems: selectCartItems,
  total: selectCartTotal,
});

export default connect(mapStateToProps)(CheckoutPage);
