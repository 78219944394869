import React from "react";
import "./App.css";
import { Switch, Route } from "react-router-dom";
import Services from "./pages/services/services.component";
import Homepage from "./pages/homepage/homepage.component";
import About from "./pages/about/about.component";
import Contact from "./pages/contact/contact.component";
import Header from "./components/header/header.component";
import Footer from "./components/footer/footer.component";
import Gallery from "./pages/gallery/gallery.component";
import Shop from "./pages/shop/shop.component";
import Checkout from "./pages/checkout/checkout.component";
import ProductContact from "./pages/product-contact/product-contact.component";
import SuccessPage from "./pages/success/success.component";
function App() {
  return (
    <div className="App">
      <Header />
      <div className="main-content content-container">
        <Switch>
          <Route exact path="/" component={Homepage}></Route>
          <Route exact path="/services" component={Services}></Route>
          <Route exact path="/about" component={About}></Route>
          <Route path="/contact/:option" component={Contact}></Route>
          <Route path="/contact" component={Contact}></Route>
          <Route path="/product-contact" component={ProductContact}></Route>
          <Route exact path="/gallery" component={Gallery}></Route>
          <Route path="/shop" component={Shop}></Route>
          <Route exact path="/checkout" component={Checkout}></Route>
          <Route exact path="/success" component={SuccessPage}></Route>
        </Switch>
      </div>
      <Footer />
    </div>
  );
}

export default App;
