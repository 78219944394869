import React from 'react';
import SERVICE_DATA from './service.data.js';
import './service-list.styles.scss';
import ServiceService from '../service-service/service-service.component';

const ServiceList = () => (
  <div className="service-list">
    {SERVICE_DATA.map(({ optionId, description, ...otherSectionProps }) => (
      <ServiceService key={optionId} optionId={optionId} {...otherSectionProps}>
        {description}
      </ServiceService>
    ))}
  </div>
);
export default ServiceList;