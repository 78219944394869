import React from "react";
import FormField from '../form-field/form-field.component';

import "./form-number.styles.scss";

const FormNumber = ({ handleChange, ...otherProps }) => (
  <FormField {...otherProps}>
    <input
      type="number"
      className="form-input form-number"
      min="0"
      onChange={handleChange}
      {...otherProps}
    />
  </FormField>
);

export default FormNumber;