import React from "react";
import "./product-contact-form.styles.scss";
import FormInput from "../form-components/form-input/form-input.component";
import FormNumber from "../form-components/form-number/form-number.component";
import FormTextarea from "../form-components/form-textarea/form-textarea.component";
import CustomButton from "../custom-button/custom-button.component";
import { createContactMessage } from "../../firebase/firebase.utils";
import { withRouter } from "react-router-dom";

class ProductContactForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {

      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      pickupDate: "",
      eventLocation: "",
      triColorQuantity: 0,
      twoColorQuantity: 0,
      message: "",
      optionSelected: "",
      status: ""
    };
  }

  componentWillMount() {
    if (this.props.match.params.hasOwnProperty("option")) {
      this.setState({
        optionSelected: this.props.match.params.option
      });
    }
  }

  sendMessage = async () => {
    const status = await createContactMessage(this.state);
    if (status === "success") {
      this.setState({
        firstName: "",
        lastName: "",
        phone: "",
        email: "",
        pickupDate: "",
        message: ""
      });
      this.props.handleFormSubmission();
    } else {
      this.props.handleFormSubmissionFailure();
    }
  };

  handleSubmit = async event => {
    event.preventDefault();
    this.setState(
      {
        status: "submitted"
      },
      this.sendMessage
    );
  };

  handleChange = event => {
    const { value, name } = event.target;
    this.setState({ [name]: value });
  };

  render() {
    return (
      <div className="contact-form">
        <form onSubmit={this.handleSubmit}>
          <fieldset disabled={this.state.status === "submitted"}>
            <fieldset className="fieldset-name">
              <div className="fieldset-name-content">
                <FormInput
                  id="form-field-first-name"
                  name="firstName"
                  type="text"
                  value={this.state.firstName}
                  required
                  label="First Name"
                  handleChange={this.handleChange}
                />
                <FormInput
                  id="form-field-last-name"
                  name="lastName"
                  type="text"
                  value={this.state.lastName}
                  handleChange={this.handleChange}
                  label="Last Name"
                  required
                />
              </div>
            </fieldset>
            <FormInput
              id="form-field-email"
              name="email"
              type="email"
              value={this.state.email}
              handleChange={this.handleChange}
              label="Email"
              required
            />
            <FormInput
              id="form-field-phone"
              name="phone"
              type="tel"
              value={this.state.phone}
              handleChange={this.handleChange}
              label="Phone Number"
              required
            />
            <FormInput
              id="form-field-pickup-date"
              name="pickupDate"
              type="date"
              value={this.state.pickupDate}
              handleChange={this.handleChange}
              label="Pickup Date"
              labelclass="shrink"
              required
            />
            <h3>Product Selection:</h3>
            <div className="product-selection">
              <div className="tri-color">
                <div className="product-name">Multi-Colored Gift Box - $20</div>
                <img className='product-image' src="/images/tri-color-box-resize.jpeg" alt="pink, white, and green cotton candy in a bakery box" />
                <div className="product-details">
                  <div className="product-description">Pink Vanilla, Birthday Cake, and Cherry-Lime packaged beautifully in a sparkly white or buffalo checkered gift wrap. Custom flavors may be available - leave a message to receive more information. </div>
                  <FormNumber id="tri-color-quantity" name="triColorQuantity"
                    label="Quantity"
                    value={this.state.triColorQuantity}
                    handleChange={this.handleChange}
                    labelclass="shrink" />
                </div>
              </div>
              <div className="two-color">
                <div className="product-name">Two-Colored Gift Box - $20</div>
                <img className='product-image' src="/images/two-color-resize.jpg" alt="blue, white, and blue cotton candy in a bakery box" />
                <div className="product-details">
                  <div className="product-description">Classic Blue Raspberry and Birthday Cake packaged beautifully in a sparkly white gift wrap. Custom flavors may be available - leave a message to receive more information. </div>
                  <FormNumber id="two-color-quantity" name="twoColorQuantity"
                    label="Quantity"
                    value={this.state.twoColorQuantity}
                    handleChange={this.handleChange}
                    labelclass="shrink" />
                </div>
              </div>
            </div>
            <FormTextarea
              id="form-field-message"
              name="message"
              value={this.state.message}
              handleChange={this.handleChange}
              label="Message"
              maxLength={1000000}
            />
            <div className="buttons">
              <CustomButton type="submit">{`${this.state.status === "submitted" ? "Submitting" : "Submit"
                }`}</CustomButton>
            </div>
          </fieldset>
        </form>
      </div>
    );
  }
}
export default withRouter(ProductContactForm);
