import React from 'react';

import './page-section.styles.scss';

const PageSection = ({ title, children }) => (
    <section className="page-section-container">
        {(title && title.length) ? <h2 className='page-section-header'>{title}</h2> : ''}
        <div className="page-section-content">{children}</div>
    </section>
)

export default PageSection;