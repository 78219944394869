var nonModalNodes = [];

export const openModal = (modalSelector) => {
  var modalNodes = Array.from(document.querySelectorAll(`${modalSelector} *`));

  
  document.body.style.overflow = 'hidden';

  // by only finding elements that do not have tabindex="-1" we ensure we don't
  // corrupt the previous state of the element if a modal was already open
  nonModalNodes = document.querySelectorAll(`body *:not(${modalSelector}):not([tabindex="-1"])`);

  for (var i = 0; i < nonModalNodes.length; i++) {
    var node = nonModalNodes[i];

    if (!modalNodes.includes(node)) {

      // save the previous tabindex state so we can restore it on close
      node._prevTabindex = node.getAttribute('tabindex');
      node.setAttribute('tabindex', -1);

      // tabindex=-1 does not prevent the mouse from focusing the node (which
      // would show a focus outline around the element). prevent this by disabling
      // outline styles while the modal is open
      // @see https://www.sitepoint.com/when-do-elements-take-the-focus/
      node.style.outline = 'none';
    }
  }
}

export const closeModal = () => {
  
  document.body.style.overflow = null;
  // restore or remove tabindex from nodes
  for (var i = 0; i < nonModalNodes.length; i++) {
    var node = nonModalNodes[i];
    if (node._prevTabindex) {
      node.setAttribute('tabindex', node._prevTabindex);
      node._prevTabindex = null;
    }
    else {
      node.removeAttribute('tabindex');
    }
    node.style.outline = null;
  }
  //clear the array.
  nonModalNodes = []
}