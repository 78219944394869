import React from 'react';

import PageHeader from "../../components/page-header/page-header.component";
import ProductContactForm from '../../components/product-contact-form/product-contact-form.component';

import "./product-contact.styles.scss";

class ProductContact extends React.Component {
  componentDidMount() {
    document.title = "Columbus Candy - Contact";
  }

  constructor() {
    super();
    this.state = {
      isFormSubmitted: false,
      isSubmissionFailure: false
    };
  }

  render() {
    const handleFormSubmission = () => {
      this.setState({ isFormSubmitted: true });
    };
    const handleFormSubmissionFailure = () => {
      this.setState({
        isSubmissionFailure: true
      });
    };

    const formSubmitted = (
      <PageHeader contentClasses="centered" title="Your message is on its way!">
        Thank you for your order. We will send an invoice for your order to the email you provided!
        <br/>
        Orders can be picked up at 5287 Garand Dr. Westerville, Ohio 43081
        <br/>
        Call 614-594-9565 for any questions.
      </PageHeader>
    );

    const formFailure = (
      <PageHeader contentClasses="centered" title="Something went wrong!">
        There was an issue submitting the contact form. Please send us an email:
        spencer@cottoncandy.com
      </PageHeader>
    );

    const formUnsubmitted = (
      <div className="contact-form-wrapper">
        <PageHeader title="Pickup Order Form">
          Use the form below to place a pickup order! An invoice will be sent to the email provided for payment.
        </PageHeader>
        <ProductContactForm
          handleFormSubmission={handleFormSubmission}
          handleFormSubmissionFailure={handleFormSubmissionFailure}
        />
      </div>
    );

    const ChooseFormDisplay = () => {
      if (this.state.isSubmissionFailure) {
        return formFailure;
      } else if (this.state.isFormSubmitted) {
        return formSubmitted;
      } else {
        return formUnsubmitted;
      }
    };

    return <div className="contact-us"><ChooseFormDisplay/></div>;
  }
}

export default ProductContact;
