import React from 'react';

import './hamburger-button.styles.scss'


const HamburgerButton = ({ isActive, handleClick }) => (
    <button onClick={handleClick} className={`burger-menu ${isActive ? 'active' : ''}`} aria-label={`${isActive ? 'Close' : 'Open'} Menu`}>
        <div className="burger"></div>
    </button>
)

export default HamburgerButton;