import React from "react";

import "./homepage-service.styles.scss";
const HomepageService = ({ children, imgSrc, imgAlt, title }) => (
  <div className="homepage-service">
    <h2 className="homepage-service-header">{title}</h2>
    <img className='homepage-service-image' src={imgSrc} alt={imgAlt} />
    <div className="homepage-service-content">{children}</div>
  </div>
);

export default HomepageService;
