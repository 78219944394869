import React from "react";
import NavigationList from "../navigation-list/navigation-list.component";
import "./footer.styles.scss";

const Footer = () => (
  <div className="footer">
    <div className="footer-content content-container">
      <div className="footer-nav">
        <NavigationList />
      </div>
      <div className="contact-infos">
        <div className="contact-info">
          <span className="company-name">Columbus Cotton Candy</span>,
          Westerville, OH 43081
        </div>
        <div className="contact-info">(614) 594-9565‬</div>
        <div className="contact-info">Spencer@ColumbusCandy.com</div>
      </div>
    </div>
  </div>
);

export default Footer;
