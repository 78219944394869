const CartActionTypes = {
  TOGGLE_CART_HIDDEN: 'TOGGLE_CART_HIDDEN',
  HIDE_CART: 'HIDE_CART',
  SHOW_CART: 'SHOW_CART',
  ADD_ITEM: 'ADD_ITEM',
  REMOVE_ITEM: 'REMOVE_ITEM',
  CLEAR_ITEM_FROM_CART : 'CLEAR_ITEM_FROM_CART',
  CLEAR_CART: 'CLEAR_CART'
};

export default CartActionTypes;