import React, { useEffect } from 'react';
import { Link } from "react-router-dom";
import PageHeader from "../../components/page-header/page-header.component";
import "./services.styles.scss";
import ServiceList from "../../components/service-list/service-list.component";

const Services = () => {
  useEffect(() => {
    document.title = "Columbus Candy - Services"
  }, []);

  return (
    <div className="services">
      <PageHeader title="Pickup Orders">
        <p>
          Our small batch orders make great party favors or surprises for friends! Visit the <Link className="inline-link" to="/shop">
            Shop Page
          </Link> for pricing on small batches and gift orders available for pickup. </p>
      </PageHeader>
      <PageHeader title="Bulk Orders">
        <p>
          Receive a discount for orders quantities over 100! <Link className="inline-link" to="/contact">
            Contact us
          </Link> for pricing and flavor options for bulk orders. </p>
      </PageHeader>
      <br />
      <PageHeader title="Cart Services">
        <p>
          <span className="company-name">Columbus Cotton Candy</span> cart service brings the magic to your event.

        </p>

        {/* <div className='modification-info'>Are these options not what you are looking for? We can be flexible to make it work. <Link className="inline-link" to="/contact">
          Send us a note!
        </Link>
          <div>Add 30 minutes of cart service, additional cones, or an additional flavor. Inquire about cost of add-ons.</div></div> */}

      </PageHeader>
      <ServiceList />
      <ul className='fees'>
        <li>If a dedicated outlet is not available, a generator can be provided for an additional fee</li>
        <li>15% fee for large groups</li>
        <li>15% fee for canceled events</li>
      </ul>
    </div>
  );
}

export default Services;
