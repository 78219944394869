import React from "react";
import { NavLink } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import "./navigation-list.styles.scss";

const NAV_DATA = [
  {
    title: "about",
    routeName: "about"
  },
  {
    title: "services",
    routeName: "services"
  },
  {
    title: "contact",
    routeName: "contact"
  },
  {
    title: "shop",
    routeName: "shop"
  },
  {
    title: "gallery",
    routeName: "gallery"
  }
];

const NavigationList = ({ handleNavClick }) => (
  <nav className="navigation-list" aria-label="main menu" role="navigation">
    <CSSTransition
      in={true}
      classNames="fade"
      appear={true}
      timeout={{}}
    >
      <ul className="nav-menu">
        {NAV_DATA.map((navItem, index) => (
          <li key={index} className="nav-item">
            <NavLink to={`/${navItem.routeName}`} onClick={handleNavClick}>
              {navItem.title}
            </NavLink>
          </li>
        ))}
      </ul>
    </CSSTransition>
  </nav>
);

export default NavigationList;
