import React from "react";

import { Link } from "react-router-dom";
import "./custom-link-button.styles.scss";

const CustomLinkButton = ({ children, isCloud, ...otherProps }) => (
  <div className={`${isCloud ? 'cloud' : ''} custom-link-button-container`} >
    <Link className="custom-link-button" {...otherProps}>
      {children}
    </Link>
  </div >
);

export default CustomLinkButton;
