import React from "react";
import { connect } from 'react-redux';
import PageHeader from "../../components/page-header/page-header.component";
import { clearCartAction } from "../../redux/cart/cart.actions";

const SuccessPage = ({clearCart}) => {

  clearCart();
  return (
    <PageHeader contentClasses="centered" title="Success!">
      Thank you for your order. You may pick up your order on the date you selected at:
      <div>5287 Garand Dr. Westerville, OH 43081</div>
    </PageHeader>
  );
};

const mapDispatchToProps = dispatch => ({
  clearCart: () => dispatch(clearCartAction())
});

export default connect(null, mapDispatchToProps)(SuccessPage);