import React, { useState } from 'react';
import './collection-item.styles.scss';

import { connect } from "react-redux";
import { addItemAction } from '../../redux/cart/cart.actions';
import CustomButton from "../custom-button/custom-button.component";
import Checkmark from "../checkmark/checkmark.component";

const CollectionItem = ({ item, addItem }) => {
  const { name, price, imageUrl, description } = item;
  const [adding, setAdding] = useState(false);
  const processAddItem = (item) => {
    setAdding(true);
    setTimeout(() => { setAdding(false) }, 3000);
    addItem(item);
  };
  return (
    <div className="collection-item-wrapper">
      <div className={`collection-item ${adding ? 'adding' : ''}`}>
        <div className="image"
          style={{ backgroundImage: `url(${imageUrl})` }}></div>

        <div className="collection-footer">
          <span className="name">{name}</span>
          <span className="price">${price.toFixed(2)}</span>
        </div>
        {adding ?
          (<CustomButton >Added to cart<Checkmark /></CustomButton>)
          :
          (<CustomButton onClick={() => processAddItem(item)} inverted>Add to cart</CustomButton>)
        }

      </div>

      <div className="description">{description}</div>
    </div >
  )
}

const mapDispatchToProps = dispatch => ({
  addItem: item => dispatch(addItemAction(item))
});

export default connect(null, mapDispatchToProps)(CollectionItem);