import React from 'react';

import './page-header.styles.scss';

const PageHeader = ({ title, children, contentClasses, subtitle }) => (
  <div className="page-header-container">
    <h1 className={`page-header ${subtitle && subtitle.length ? "has-sub-header" : ""} `}>{title}</h1>
    {(subtitle && subtitle.length) ? <div className='sub-page-header'>{subtitle}</div> : ''}
    <div className={`page-header-content ${contentClasses}`}>{children}</div>
  </div>
)

export default PageHeader;