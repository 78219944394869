import React, { useEffect } from 'react';
import { Link } from "react-router-dom";

import "./about.styles.scss";
import PageHeader from "../../components/page-header/page-header.component";
import Flavors from "../../components/flavors/flavors.component";

const About = () => {
  useEffect(() => {
    document.title = "Columbus Candy - About"
  }, []);

  return (
    <div className="about-page">

      <PageHeader title="About">
        <div className="about-header-container">
          <img className='about-image' src="/images/sit_cart.jpg" alt="Smiling Spencer wearing an apron sitting in front of a cotton candy cart" />
          <div>
            <p>
              Hi! I'm Spencer, the cotton candy guy from Columbus, Ohio. I started{" "}
              <span className="company-name">Columbus Cotton Candy</span> with the
              hope of sharing the magic of hand-spun confections with my community. We
              have created exciting and unique new flavors for you to try.
              Flavors that go above and beyond the standard 'Pink' and 'Blue'.
            </p>
          </div>
        </div>
      </PageHeader>
      <Flavors />
      {/* <PageSection title="Give us a try!"> */}
      <div className="conclusion">
        <Link className="inline-link" to="/contact">
          Contact us
        </Link>{" "}
        to reserve our candy cart for your next event and you can count on us to
        delight your guests with a cotton candy experience they will remember!
      </div>
    </div>
  );
}
export default About;
