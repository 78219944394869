import React from 'react';

import PageHeader from "../../components/page-header/page-header.component";
import ContactForm from "../../components/contact-form/contact-form.component";

import "./contact.styles.scss";

class Contact extends React.Component {
  componentDidMount() {
    document.title = "Columbus Candy - Contact";
  }

  constructor() {
    super();
    this.state = {
      isFormSubmitted: false,
      isSubmissionFailure: false
    };
  }

  render() {
    const handleFormSubmission = () => {
      this.setState({ isFormSubmitted: true });
    };
    const handleFormSubmissionFailure = () => {
      this.setState({
        isSubmissionFailure: true
      });
    };

    const formSubmitted = (
      <PageHeader contentClasses="centered" title="Your message is on its way!">
        Thank you for contacting us. We will get back to you as soon as we can!
      </PageHeader>
    );

    const formFailure = (
      <PageHeader contentClasses="centered" title="Something went wrong!">
        There was an issue submitting the contact form. Please send us an email:
        spencer@cottoncandy.com
      </PageHeader>
    );

    const formUnsubmitted = (
      <div className="contact-form-wrapper">
        <PageHeader title="Contact us">
          We are excited to help you celebrate your upcoming event. If you are
          interested in booking us or have any questions, let us know with the
          form below and we’ll get back to you as soon as we can!
        </PageHeader>
        <ContactForm
          handleFormSubmission={handleFormSubmission}
          handleFormSubmissionFailure={handleFormSubmissionFailure}
        />
      </div>
    );

    const ChooseFormDisplay = () => {
      if (this.state.isSubmissionFailure) {
        return formFailure;
      } else if (this.state.isFormSubmitted) {
        return formSubmitted;
      } else {
        return formUnsubmitted;
      }
    };

    return <div className="contact-us"><ChooseFormDisplay/></div>;
  }
}

export default Contact;
