import React from "react";

import "./form-field.styles.scss";

const FormField = ({ label, ...otherProps }) => (
  <div className="group">
    {otherProps.children}
    {label ? (
      <label
        htmlFor={otherProps.id}
        className={`${
          otherProps.value.length ? "shrink" : ""
        } form-input-label ${otherProps.labelclass}`}
      >
        {label}
        {otherProps.required ? <span className="required">*</span> : null}
      </label>
    ) : null}
  </div>
);

export default FormField;
