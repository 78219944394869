import React from 'react';

// import NavigationList from '../navigation-list/navigation-list.component';

import './mobile-nav.styles.scss';

const MobileNav = (props) => (
    <div className="mobile-nav" {...props}>{props.children}</div>
)

export default MobileNav;