import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { withRouter, Link } from 'react-router-dom';

import CustomButton from '../custom-button/custom-button.component';
import CartItem from '../cart-item/cart-item.component';
import { selectCartItems } from "../../redux/cart/cart.selectors";
import { toggleCartHidden } from '../../redux/cart/cart.actions.js';

import './cart-dropdown.styles.scss';

const CartDropdown = ({ cartItems, history, dispatch }) => (
  <div className='cart-dropdown'>
    <button className="cart-close-icon" aria-label="close cart drop down" onClick={() => { dispatch(toggleCartHidden()); }} >&#10006;</button>
    <div className='cart-items'>
      {
        cartItems.length ?
          (cartItems.map((cartItem) => (
            <CartItem key={cartItem.id} item={cartItem} />))) :
          <div><span className="empty-message">Your cart is empty.</span><div><Link className="inline-link" to="/shop" onClick={() => { dispatch(toggleCartHidden()); }} >
            - Go to shop page -
          </Link></div></div>
      }
    </div>
    <CustomButton onClick={() => {
      history.push('/checkout');
      dispatch(toggleCartHidden());
    }} >GO TO CHECKOUT</CustomButton>
  </div>
);

const mapStateToProps = createStructuredSelector({
  cartItems: selectCartItems
});

export default withRouter(connect(mapStateToProps)(CartDropdown));
