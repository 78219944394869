import React from "react";
import "./header.styles.scss";
import { Link } from "react-router-dom";
import Logo from "../logo/logo.component";

import { connect } from "react-redux";
import CartIcon from '../cart-icon/cart-icon.component';
import { createStructuredSelector } from 'reselect';
import { selectCartHidden } from "../../redux/cart/cart.selectors";
import { hideCart } from "../../redux/cart/cart.actions";

import NavigationList from "../navigation-list/navigation-list.component";
import HamburgerButton from "../hamburger-button/hamburger-button.component";
import MobileNav from "../mobile-nav/mobile-nav.component";
import { openModal, closeModal } from "../../utilities/modal.utils";
import CartDropdown from "../cart-dropdown/cart-dropdown.component";
class Header extends React.Component {
  constructor(props) {
    super(props);
    //todo: add this state to redux and convert this component to functional?
    this.state = {
      isHamburgerMenuOpen: false,
      headerHeight: ""
    };
  }

  render() {
    const handleHamburgerClick = () => {
      const newMenuOpenState = !this.state.isHamburgerMenuOpen;

      this.setState({
        isHamburgerMenuOpen: !this.state.isHamburgerMenuOpen,
        headerHeight: `${document.getElementById("header").offsetHeight}px`
      });

      //TODO: on browser resize from mobile to desktop need to unlock the modal lock
      newMenuOpenState ? openModal(".header") : closeModal();
    };
    const handleNavClick = () => {
      this.setState({
        isHamburgerMenuOpen: false
      });
      closeModal();
      this.props.hideCart();
    };

    return (
      <div className="header" id="header">
        <div className="header-content content-container">
          <Link onClick={handleNavClick} className="logo-container" to="/">
            <Logo />
          </Link>
          <div className="desktop-nav">
            <NavigationList handleNavClick={this.props.hideCart} />
            <CartIcon />
            {this.props.hidden ? null : <CartDropdown />}

          </div>

          <div className='mobile-header'>
            <CartIcon />
            <HamburgerButton
              handleClick={handleHamburgerClick}
              isActive={this.state.isHamburgerMenuOpen}
            />
            {this.props.hidden ? null : <CartDropdown />}
          </div>
        </div>
        {this.state.isHamburgerMenuOpen ? (
          <MobileNav style={{ top: `${this.state.headerHeight}` }}>
            <NavigationList handleNavClick={handleNavClick} />
          </MobileNav>
        ) : null}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  hideCart: () => dispatch(hideCart()),
});

const mapStateToProps = createStructuredSelector({
  hidden: selectCartHidden
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
