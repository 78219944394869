import React from "react";
import stickLogo from "../../assets/stick.svg";
import "./logo.styles.scss";

const Logo = ({ children, isCloud, ...otherProps }) => (
  <div className="logo">
    <div className="logo-name">
      <div className="logo-name-top">Columbus</div>
      <div className="logo-name-bottom">Cotton Candy</div>
    </div>
    <div className="logo-image">
      <img src={stickLogo} className="logo-image-icon" alt='cartoon smiling cotton candy' />
    </div>
  </div>
);

export default Logo;
