import firebase from "firebase/app"; //pulling in firebase utility library.
import 'firebase/analytics';
import "firebase/firestore"; //for database
import "firebase/functions";
import { loadStripe } from '@stripe/stripe-js';


//npm add firebase
//manually add this file and reference in app.js
//add below config from firebase dashboard
//go into set up sign in method in authentication page on firebase website

const config = {
  apiKey: "AIzaSyDNk6VQ6RJkzqJJYPAppMFPOkK8s5MPYoc",
  authDomain: "columbus-candy.firebaseapp.com",
  databaseURL: "https://columbus-candy.firebaseio.com",
  projectId: "columbus-candy",
  storageBucket: "columbus-candy.appspot.com",
  messagingSenderId: "936693231480",
  appId: "1:936693231480:web:aa39d7f01d0f0d95b2628d",
  measurementId: "G-B7ZQDNL91L"
};

firebase.initializeApp(config);
firebase.analytics();

const createStripeCheckout = firebase.functions().httpsCallable('createStripeCheckout');
export const firestore = firebase.firestore();

export const createContactMessage = async formData => {
  if (!formData) return;

  const createdAt = new Date();
  try {
    const docRef = await firestore.collection('contact-messages').add({ createdAt, ...formData });
    console.log(`Document written with ID: ${docRef.id}`);
    return 'success';
  } catch (error) {
    //todo: show error to user and provide email address and form details.
    console.error("Error adding document: ", error);
    return 'failed';
  }
};

export const sendToStripeCheckout = async (cartItems, pickupDate) => {
  const stripe = await loadStripe('pk_live_51JHeb3IiYvumyudOyxKJuCVxMoTggWvdwBE4GNtLicXZiFi2Om2H4cLWXdsK0Tq5frDC9OaSYkS3mkRDBBxIxoBw00aO4Wazhu');


  let lineItems = [];
  //todo convert this to map
  cartItems.forEach((cartItem) => {
    lineItems.push({
      quantity: cartItem.quantity,
      price_data: {
        currency: "usd",
        unit_amount: (cartItem.price) * 100, // 10000 = 100 USD
        product_data: {
          name: cartItem.name,
          metadata: {
            productPickupDate: pickupDate
          }
        },
      }
    })
  });

  const stripeCheckoutData = {
    lineItems: lineItems,
    metadata: {
      pickupDate: pickupDate,
    },
  }

  createStripeCheckout(stripeCheckoutData)
    .then(response => {
      const sessionId = response.data.id;
      stripe.redirectToCheckout({ sessionId: sessionId });
    })
}

export default firebase;
